import React from 'react'
import ColumnNew from '../components/ColumnNew';
import { Header } from '../components/Header';

const Profile = () => {
  return (
    <>
      <Header />
      <div className='Profile-Style'>
        <ColumnNew type="owned"/>
      </div>
    </>
  )
}

export default Profile;
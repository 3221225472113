import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { APP_DATA } from "../utils";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { Buffer } from "buffer";
import { InjectedConnector } from "@web3-react/injected-connector";

const projectId = "2DWJHtZRz0Y2jpsPSKCxopx5f69";
const projectSecret = "fbe3801f275d21e445197918b7334672";
const projectIdAndSecret = `${projectId}:${projectSecret}`;
const ipfsURL = "https://braands.infura-ipfs.io/ipfs/";

const client = ipfsHttpClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: `Basic ${Buffer.from(projectIdAndSecret).toString("base64")}`,
  },
});
const metaMaskConnector = new InjectedConnector({ supportedNetworks: [1, 4] });

const Mint = () => {
  const { library, activate, account } = useWeb3React();
  const [dataURL, setDataURL] = useState(null);
  const [input, setInput] = useState({
    title: "",
    price: "",
    description: "",
    images: [],
    documents: [],
  });

  const changeHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const onFileChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.files });
  };

  const connectMetaMask = async () => {
    try {
      await activate(metaMaskConnector);
    } catch (error) {
      console.log(error);
    }
  };

  async function uploadFileToIpfs(currentFile) {
    const file = currentFile;
    try {
      const added = await client.add(file, {
        progress: (prog) => {
          console.log(`received: ${prog}`);
        },
      });
      const url = `${ipfsURL}${added.path}`;
      return url;
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  async function uploadDataToIPFS() {
    const { title, price, description } = input;

    let images = [];
    let documents = [];
    
    Array.from(input.images).map(async (ele, index) => {
      uploadFileToIpfs(ele).then(tempImg=>{
        console.log(tempImg)
        images.push(tempImg);
        if(index === input.images.length-1) {
          Array.from(input.documents).map(async (ele, index1) => {
            uploadFileToIpfs(ele).then(tempDoc=>{
              console.log(tempDoc);
              documents.push(tempDoc);
              if(index1 === input.documents.length-1) {
                const data = JSON.stringify({
                  title, description, price, images, documents
                })
                try {
                    client.add(data).then(added=>{
                      const url = `${ipfsURL}${added.path}`
                      console.log(url,"Done");
                      setDataURL(url);
                    })
                    /* after file is uploaded to IPFS, return the URL to use it in the transaction */
                } catch (error) {
                    console.log('Error uploading file: ', error)
                }
              }
            })
          });
        }
      })
    });
  }

  const mintItem = () => {
    if (library) {
      uploadDataToIPFS();
    } else {
      connectMetaMask();
    }
  };

  useEffect(()=>{
    if(dataURL){
      const price = library.utils.toWei(input.price.toString());
      let contract = new library.eth.Contract(APP_DATA.ABI, APP_DATA.contractAddress);
        contract.methods.mint(account, 100000, dataURL, price, 1).send({ from: account, value: price }).then((transaction) => {
          alert("Mint Successfully.");
        })
    }
  },[dataURL]);

  useEffect(()=>{
    connectMetaMask();
  },[]);

  return (
    <div>
      <div className="container p-5">
        <h1 className="mb-4">Mint</h1>
        <div className="mb-3">
          <input type="text" className="form-control" name="title" placeholder="Enter your title" value={input.title} onChange={changeHandler} />
        </div>
        <div className="mb-3">
          <input type="text" className="form-control" name="price" placeholder="Enter your price" value={input.price} onChange={changeHandler} />
        </div>
        <div className="mb-3">
          <textarea
            className="form-control"
            rows="4"
            name="description"
            value={input.description}
            onChange={changeHandler}
            placeholder="Enter your description"></textarea>
        </div>
        <div className="mb-3">
          <h6 className="mb-3">Images</h6>
          <input type="file" name={"images"} onChange={onFileChange} multiple />
        </div>
        <div className="mb-3">
          <h6 className="mb-3">Documents</h6>
          <input type="file" name={"documents"} onChange={onFileChange} multiple />
        </div>
        <div className="mb-3">
          <input type="submit" className="button text-white" onClick={mintItem} />
        </div>
      </div>

      <div className="">
        
      </div>
    </div>
  );
};

export default Mint;

import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

const metaMaskConnector = new InjectedConnector({ supportedNetworks: [1, 4] });

export const Header = () => {
  const [popup, setPopup] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
  const { active, account, library, activate, deactivate } = useWeb3React();
  let navigate = useNavigate();

  const handleToggle = () => {
    setPopup((oldValue) => !oldValue);
  };

  const handleIsActive = async () => {
    try {
      let metaMaskActive = await metaMaskConnector.isAuthorized();
      if (localStorage.getItem("walletDetails")) {
        if (metaMaskActive) {
          let walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
          if (walletDetails.address) {
            await activate(metaMaskConnector);
          }
        } else {
          localStorage.removeItem("walletDetails");
        }
      } else if(active) {
        let walletDetails = JSON.stringify({ address: account });
        localStorage.setItem("walletDetails", walletDetails);
      }
      active && getBalance();
    } catch (error) {
      console.log(error);
    }
  };

  const signOut = () => {
    try {
      deactivate(metaMaskConnector);
      localStorage.removeItem("walletDetails");
      setTimeout(() => navigate("/"), 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const connectMetaMask = async () => {
    try {
      await activate(metaMaskConnector);

      setTimeout(handleToggle, 1000)
    } catch (error) {
      console.log(error);
    }
  };

  const getBalance = () => {
    library.eth.getBalance(account, (error, result)=>{
      if(!error) setWalletBalance(library.utils.fromWei(result));
    });
  };

  useEffect(() => {
    handleIsActive();
    if(account) {
      getBalance();
    }
  }, [active, account]);

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-xl">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src="assets/images/logo.png" alt="Logo" className="logo" />
            </a>
            <div className="navbar__out order-2 order-xl-3">
              {active ? (
                <>
                  <span>{`${parseFloat(walletBalance).toFixed(4)} BNB`}</span>
                  <span>{`${account.slice(0, 5)}...${account.slice(account.length - 4, account.length + 1)}`}</span>
                  <span style={{cursor: 'pointer'}} onClick={()=>navigate('/profile')}>Profile</span>
                  <span onClick={signOut} className="button text-white">Logout</span>
                </>
              ) : (
                <div className="nav__group__btn">
                  <span href="#" className="button button--effect d-none d-sm-block text-white" onClick={handleToggle}>
                    Connect Wallet <i className="fa-solid fa-arrow-right-long"></i>
                  </span>
                </div>
              )}
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#primaryNav"
                aria-controls="primaryNav"
                aria-expanded="false"
                aria-label="Toggle Primary Nav">
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>
            </div>
            <div className="collapse navbar-collapse order-3 order-xl-2" id="primaryNav">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/marketplace">
                    Properties
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      {
        <Modal show={popup} onHide={handleToggle}>
          <Modal.Header closeButton>
            <h4>Connect Wallet</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row p-2">
              <div className="col-lg-6">
                <span className="box-url py-5 text-center" onClick={connectMetaMask} style={{ cursor: "pointer" }}>
                  <img src="./assets/images/wallet/1.png" alt="" className="mt-2" />
                  <h5 className="text-center mt-2">Metamask</h5>
                </span>
              </div>
              <div className="col-lg-6">
                <span className="box-url py-5 text-center" style={{ cursor: "pointer" }}>
                  <img src="./assets/images/wallet/4.png" alt="" className="mt-2" />
                  <h5 className="text-center mt-2">WalletConnect</h5>
                </span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { APP_DATA } from "../utils";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Web3 from 'web3/dist/web3.min.js';
import {useNavigate, Link} from 'react-router-dom';

const ColumnNew = ({ type = null }) => {
  const { library, account, active } = useWeb3React();
  const [NFTData, setNFTData] = useState(null);
  const [NFTId, setNFTId] = useState(null);
  const [currentNFTEarning ,setCurrentNFTEarning] = useState('');
  const [listItemInput, setListItemInput] = useState({
    amount: ''
  })
  const [listItemPopup, setListItemPopup] = useState(false);
  const toggleListItemPopup = ()=>{
    setListItemPopup(prev=> !prev);
  }
  const navigate = useNavigate();

  const getOwnedItems = async () => {
    if (library) {
      let contract = new library.eth.Contract(APP_DATA.ABI, APP_DATA.contractAddress);
      const maxTokenId = await contract.methods.tokenIds().call();
      let data = [];
      for (let i = 1; i <= maxTokenId; i++) {
        let balanceOf = await contract.methods.balanceOf(account, i).call();
        if (balanceOf != 0) {
          let mint_time = await contract.methods.Mint_time(i).call();
          let validity = await contract.methods.CheckValidity(i).call();
          let initial_price = await contract.methods.intial_price(i).call();
          initial_price = library.utils.fromWei(initial_price);
          let earning = await contract.methods.getEarning(account, i).call();
          earning = earning / 100000000;
          let max_supply = await contract.methods.max_supply(i).call();
          let balance_of = await contract.methods.balanceOf(account, i).call();
          let token_uri = await contract.methods.tokenURI(i).call();
          let title, description, images, documents;
          let token_uri_split = token_uri.split(".");
          if (token_uri_split.includes("infura-ipfs")) {
            try {
              const resAPI = await axios.get(token_uri);
              title = resAPI.data.title;
              description = resAPI.data.description;
              images = resAPI.data.images;
              documents = resAPI.data.documents;
            } catch (error) {
              console.log(error, "123");
            }
          }
          data.push({ token_id: i, balance_of, earning ,title, description, images, documents, mint_time, validity, initial_price, max_supply, token_uri });
        }
      }
      setNFTData(data);
    }
  };

  const withdrawal = async () => {
    if(library){
      let contract = new library.eth.Contract(APP_DATA.ABI, APP_DATA.contractAddress);
      let amount = listItemInput.amount * 100000000;
      contract.methods.Withdrawal(account, NFTId, amount).send({from: account}).then((transtion)=>{
        alert("Withdrawal Successfully.");
        setTimeout(()=>  window.open("/profile", "_self") , 1000);
      })
    }
  }

  const getMarketplaceNft = async () => {
    let web3 = new Web3(Web3.givenProvider || 'https://data-seed-prebsc-1-s1.binance.org:8545');
    let contract = new web3.eth.Contract(APP_DATA.ABI, APP_DATA.contractAddress);
    let maxItemId = await contract.methods.itemIds().call();
    let items = [];
    for (let i = 1; i <= maxItemId; i++) {
        let item = await contract.methods.idToMarketItem(i).call();
        let token_uri = await contract.methods.tokenURI(item.tokenId).call();
        let isItemSold = await contract.methods.IsItemsoldout(item.itemId).call();
        let title, description, images, documents;
        let token_uri_split = token_uri.split(".");
        if (token_uri_split.includes("infura-ipfs")) {
            const resAPI = await axios.get(token_uri);
            title = resAPI.data.title;
            description = resAPI.data.description;
            images = resAPI.data.images;
            documents = resAPI.data.documents;
        }
        if (!isItemSold) items.push({ ...item, title, description, images, documents });
    }
    setNFTData(items);
  }

  useEffect(() => {
    if (type === "owned") getOwnedItems();
    else if (type === "marketplace") getMarketplaceNft();
  }, [active]);

  return (
    <section style={{ paddingTop: "150px" }}>
      <div className="container">
        {type === "marketplace" ? <h3 className="mb-4">Marketplace</h3> : type === "owned" && <h3 className="mb-4">Owned Items</h3>}
        <div className="row">
          {type === "owned" 
          ? <>
          {NFTData && NFTData.map((ele, index) => (
              <div className="col-lg-6 col-md-6 col-xl-4 h-100" key={index}>
                <div className="property__grid__single column__space--secondary">
                  <div className="img__effect">
                    {console.log(ele)}
                    <a href="#">
                      <img src={ele.images.length > 0 && ele.images[0]} alt="Property" style={{height: '300px', objectFit: 'cover'}} />
                    </a>
                  </div>
                  <div className="property__grid__single__inner">
                    <h4>{ele.title}</h4>
                    <p className="sub__info">
                      <i className="fa-solid fa-location-dot"></i> {ele.description}
                    </p>
                    <div className="progress__type">
                      <div className="progress">
                        <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <p className="project__has">
                        <span className="project__has__investors">{ele.balance_of} Shares</span>
                      </p>
                    </div>
                    <div className="item__info">
                      <div className="item__info__single">
                        <p>Your Earning</p>
                        <h6>{ele.earning}</h6>
                      </div>
                      <div className="item__info__single">
                        <p>Property Type</p>
                        <h6>Commercial</h6>
                      </div>
                    </div>
                    <div className="invest__cta__wrapper">
                      <div className="invest__cta">
                        <span
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "block",
                          }}
                          onClick={()=> {toggleListItemPopup(); setNFTId(ele.token_id); setCurrentNFTEarning(ele.earning); }}
                          className="button button--effect text-white">
                          Withdrawal Earning
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </> : <>
          {NFTData &&
            NFTData.map((ele, index) => (
              <div className="col-lg-6 col-md-6 col-xl-4 h-100" key={index}>
                <div className="property__grid__single column__space--secondary">
                  <div className="img__effect">
                    <Link to={"/property-details?NFTId="+ele.itemId}>
                      <img src={ele.images.length > 0 && ele.images[0]} alt="Property" style={{height: '300px', objectFit: 'cover'}} />
                    </Link>
                  </div>
                  <div className="property__grid__single__inner">
                    <h4>{ele.title}</h4>
                    <p className="sub__info">
                      <i className="fa-solid fa-location-dot"></i> {ele.description}
                    </p>
                    <div className="progress__type">
                      <div className="progress">
                        <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <p className="project__has">
                        <span className="project__has__investors">{159} Investors</span> |
                        <span className="project__has__investors__amount">
                          <i className="fa-solid fa-dollar-sign"></i> 1,94,196
                        </span>
                        <span className="project__has__investors__percent">(64.73%)</span>
                      </p>
                    </div>
                    <div className="item__info">
                      <div className="item__info__single">
                        <p>Annual Return</p>
                        <h6>7.5% + 2%</h6>
                      </div>
                      <div className="item__info__single">
                        <p>Property Type</p>
                        <h6>Commercial</h6>
                      </div>
                    </div>
                    <div className="invest__cta__wrapper">
                      <div className="invest__cta">
                        <span
                          style={{width: "100%", height: "100%", display: "block"}}
                          onClick={()=> navigate("/property-details?NFTId="+ele.itemId)}
                          className="button button--effect text-white">
                          Buy Now
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </> }
        </div>
      </div>

      <Modal show={listItemPopup} onHide={toggleListItemPopup}>
          <Modal.Header closeButton>
            <h4>Withdrawal Earning</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label>Enter Amount</label>
              <input type="text" className="form-control" name="sellPrice" onChange={(e)=> setListItemInput({...listItemInput, "amount" : e.target.value})} value={listItemInput.amount} placeholder="Enter Amount"/>
            </div>
            
            <div className="mb-3">
              <input type="button" className="button my-3 text-white" onClick={()=> withdrawal()} value="Withdraw"/>
            </div>
          </Modal.Body>
        </Modal>
    </section>
  );
};

export default ColumnNew;

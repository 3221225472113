import Home from "./pages/Home";
import {Routes, Route} from 'react-router-dom';
import Marketplace from "./pages/Marketplace";
import Mint from "./pages/Mint";
import Profile from "./pages/Profile";
import PropertyDetails from "./pages/PropertyDetails";
import Admin from "./pages/Admin";

function App() {
  return (
    <>
    <Routes>
      <Route exact path="/" element={<Home />}/>
      <Route exact path="/marketplace" element={<Marketplace />}/>
      <Route exact path="/mint" element={<Mint />}/>
      <Route exact path="/profile" element={<Profile />}/>
      <Route exact path="/property-details" element={<PropertyDetails />}/>
      <Route exact path="/admin" element={<Admin />}/>
    </Routes>
    </>
  );
}

export default App;

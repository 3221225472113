import React from 'react'
import ColumnNew from '../components/ColumnNew'
import { Header } from '../components/Header'

const Marketplace = () => {
  return (
    <>
    <Header/>
    <ColumnNew type="marketplace"/>
    </>
  )
}

export default Marketplace